<template>
  <div>
    <Movementmenu />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายละเอียดผู้ยืนย้าย (ประมวลผลแบบที่ 1)"
      >
        <v-card class="pa-2">
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  <v-btn
                    rounded
                    color="info"
                    :href="
                      '#/admin/print_report_movement_online_2/' +
                        periods.period_times +
                        '/' +
                        periods.period_year +
                        '/' +
                        'teach'
                    "
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน แบบที่ 1
                    (เพิ่มเติม)</v-btn
                  >
                </td>

                <td class="text-center">
                  <v-btn
                    rounded
                    color="info"
                    :href="
                      '#/admin/print_report_movement_online/' +
                        periods.period_times +
                        '/' +
                        periods.period_year +
                        '/' +
                        'teach'
                    "
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน แบบที่ 1
                    ข้อมูลดิบใช้พิจารณา</v-btn
                  >
                </td>
                <td class="text-center">
                  <v-btn
                    rounded
                    color="info"
                    :href="
                      '#/admin/print_report_condition_all/' +
                        periods.period_times +
                        '/' +
                        periods.period_year
                    "
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน แบบที่ 2
                    แบบอัตราว่าง</v-btn
                  >
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <v-btn
                    rounded
                    color="red"
                    @click="search_not_confirm_submit()"
                    ><v-icon>mdi-account-search</v-icon>
                    ไม่ได้บันทึกรายการ</v-btn
                  >
                </td>

                <td class="text-center">
                  <v-btn
                    @click="transference_locationQueryCC()"
                    rounded
                    color="warning"
                  >
                    <v-icon>mdi-alert</v-icon>
                    แจ้งยกเลิกไฟล์แนบปลายทางรับฟังความคิดเห็น</v-btn
                  >
                </td>

                <td class="text-center">
                  <v-btn                
                    rounded
                    color="info"
                    :href="
                      '#/admin/print_report_movement_formscore7/?period_times=' +
                        periods.period_times +
                        '&period_year=' +
                        periods.period_year
                    "
                    target="_blank"
                  >
                  
                    <v-icon>mdi-printer</v-icon> แบบบันทึกผลคะแนน กลั่นกรอง
                    (7)</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          filled
          class="mb-2"
        />

        <v-data-table
          :headers="showHeaders"
          :items="transference_locations"
          :search="search"
          :loading="loading"
          :item-class="row_classes"
        >
          <template v-slot:item.tpvecprovince_scoreFile="{ item }">
            <v-btn
              v-if="item.tpvecprovince_scoreFile"
              text
              rounded
              :href="'/HRvecfiles/' + item.tpvecprovince_scoreFile"
              target="_blank"
              ><v-icon color="info">mdi-printer</v-icon></v-btn
            >
          </template>

          <template v-slot:item.select_item="{ item }">
            <v-checkbox
              v-model="search"
              :value="item.new_college_code"
            ></v-checkbox>
          </template>

          <template v-slot:item.status_position="{ item }">
            <v-chip v-if="item.status_position == null" color="red" dark>
              <v-icon>mdi-close</v-icon>
            </v-chip>
            <v-chip v-else color="green" dark>
              <span style="font-size:16px;">{{ item.status_position }}</span>
            </v-chip>
          </template>
          <template v-slot:item.comment_dr="{ item }">
            <v-chip
              class="ma-1"
              v-if="item.comment_dr === 'approp'"
              color="green"
              dark
            >
              <h2>เห็นควร</h2>
            </v-chip>
            <v-chip
              class="ma-1"
              v-else-if="item.comment_dr === 'inapprop'"
              color="warning"
              dark
            >
              <h2>ไม่เห็นควร</h2>
            </v-chip>
            <v-chip v-else-if="item.comment_dr === 'suspend'" color="red" dark
              >ระงับย้าย</v-chip
            >
            <v-chip class="ma-1" v-else color="info" dark>
              <h2>ยังไม่ได้บันทึกข้อมูล</h2>
            </v-chip>

            <v-chip
              v-if="item.status_document === 'complete'"
              color="green"
              @click.stop="comment_idPosition(item.tid_ref)"
              dark
              >ถูกต้องสมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.status_document === 'incomplete'"
              color="warning"
              dark
              @click.stop="comment_idPosition(item.tid_ref)"
              >ไม่สมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.status_document === 'do_not'"
              color="red"
              dark
              @click.stop="comment_idPosition(item.tid_ref)"
              >ไม่จัดส่ง</v-chip
            >
          </template>

          <template v-slot:item.Mcommittee="{ item }">
            <v-icon color="red" large v-if="item.tlstatus_transfer === 'cc'"
              >mdi-information</v-icon
            >

            <v-chip v-if="item.Mcommittee === 'approp'" color="green" dark>
              เห็นควร
            </v-chip>
            <v-chip
              v-else-if="item.Mcommittee === 'inapprop'"
              color="warning"
              dark
              @click="cancelFile(item.id_tfl)"
            >
              ไม่เห็นควร
            </v-chip>

            <v-icon
              v-if="item.tlstatus_transfer === 'cc'"
              @click="cancelFile(item.id_tfl)"
              large
              color="red"
              >mdi-delete-circle</v-icon
            >
          </template>

          <template v-slot:item.MdocumentsLinkfile="{ item }">
            <div v-if="item.MdocumentsLinkfile">
              <v-btn
                text
                rounded
                color="green"
                large
                :href="'/HRvecfiles/' + item.MdocumentsLinkfile"
                target="_blank"
                ><v-icon>mdi-printer</v-icon></v-btn
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์ ผลการพิจารณา/วาระประชุม
              </v-chip>
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Movementmenu from "../../components/admin/movementmenu.vue";

export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      name_position_s: [
        { text: "สายการสอนและสนับสนุนการสอน", value: "ครู" },
        { text: "สายงานบริหารสถานศึกษา", value: "บริหาร" }
      ],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },

      transference_locations: [],
      transference_locationsCc: [],
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        /*  { text: "อ้างอิง", align: "center", value: "id_ref" }, */
        { text: "เลือก", align: "center", value: "select_item" },
        { text: "รหัสสถานศึกษา", align: "center", value: "new_college_code" },
        { text: "สถานศึกษาที่ขอย้ายไป", align: "center", value: "new_college" },
        { text: "ตำแหน่งว่าง", align: "center", value: "count_manpower" },
        { text: "สาขาที่เปิดรับ", align: "center", value: "name_branch_all" },
        {
          text: "เลขที่ตำแหน่งว่าง",
          align: "center",
          value: "id_position_all"
        },
        { text: "On/Off.", align: "center", value: "status_position" },
        { text: "ลำดับที่ขอย้าย", align: "center", value: "tlsequence_n" },
        { text: "อายุงาน", align: "center", value: "age_app_time" },
        { text: "รหัสสาขา", align: "center", value: "id_branch_tran" },
        { text: "สาขา", align: "center", value: "name_branch" },
        { text: "รหัสบัตร", align: "center", value: "id_cards" },
        { text: "ผู้ขอย้าย", align: "center", value: "personnel_name" },
        {
          text: "ปัจจุบันดำรงตำแหน่ง",
          align: "center",
          value: "position_name"
        },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_postion" },
        { text: "สังกัด", align: "center", value: "old_college" },
        { text: "เหตุผล", align: "center", value: "reason" },
        { text: "ความคิดเห็น ผอ.", align: "center", value: "comment_dr" },
        { text: "เนื่องจาก.", align: "center", value: "detail_comment" },
        { text: "ปลายทาง.", align: "center", value: "Mcommittee" },
        { text: "ปลายทาง.", align: "center", value: "MdocumentsLinkfile" },
        {
          text: "คะแนน สอจ.",
          align: "center",
          value: "tpvecprovince_score"
        },
        {
          text: "ไฟล์ สอจ.",
          align: "center",
          value: "tpvecprovince_scoreFile"
        },
        { text: "แห่งใหม่ ส.", align: "center", value: "succ_college" },
        {
          text: "วิทยาลัยแห่งใหม่",
          align: "center",
          value: "college_name_suss"
        },
        { text: "เลขที่", align: "center", value: "id_position_new" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      periods: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.periodQuery();
    await this.transference_locationQueryAll();
  },
  methods: {
    async cancelFile(id_tfl) {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_tfl: id_tfl
      });

      this.transference_locationsCc = result.data;
      let text =
        this.transference_locationsCc.frist_name +
        " " +
        this.transference_locationsCc.last_name;

      Swal.fire({
        title: "คุณต้องการเปลี่ยนสถานะ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยกเลิกไฟล์แนบความคิดเห็นปลายทาง",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.transference_locationsCc.ApiKey = this.ApiKey;
          this.transference_locationsCc.tlstatus_transfer = "";
          this.transference_locationsCc.tlMcommittee = "";
          let resultdel = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.transference_locationsCc.tlMdocumentsLinkfile
          });
          this.transference_locationsCc.tlMdocumentsLinkfile = "";

          let resultupdate = await this.$http.post(
            "transference_location.update.movein.php",
            this.transference_locationsCc
          );
          await this.transference_locationQueryAll();
        }
      });
    },
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_id: "301"
      });
      this.periods = result_period.data;
    },

    async search_not_confirm_submit() {
      this.loading = true;
      let result = await this.$http
        .post("transference_location.php", {
          ApiKey: this.ApiKey,
          not_confirm: "ok"
        })
        .finally(() => (this.loading = false));
      this.transference_locations = result.data;
    },
    async transference_locationQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_location_detail.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year
        })
        .finally(() => (this.loading = false));
      this.transference_locations = result.data;
    },
    async transference_locationQueryCC() {
      this.loading = true;
      let result = await this.$http
        .post("transference_location_detail.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          tlstatus_transfer: "cc"
        })
        .finally(() => (this.loading = false));
      this.transference_locations = result.data;
    },

    getColor() {
      return "green";
    },
    row_classes(item) {
      if (item.tlstatus_transfer == "cc") {
        return "yellow";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "grey darken-1";
    },
    period_years() {
      let yyyy = this.periods.period_year;
      return yyyy;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { Movementmenu }
};
</script>
