var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Movementmenu'),_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{attrs:{"icon":"mdi-clipboard-text","title":"รายละเอียดผู้ยืนย้าย (ประมวลผลแบบที่ 1)"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'#/admin/print_report_movement_online_2/' +
                      _vm.periods.period_times +
                      '/' +
                      _vm.periods.period_year +
                      '/' +
                      'teach',"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" พิมพ์รายงาน แบบที่ 1 (เพิ่มเติม)")],1)],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'#/admin/print_report_movement_online/' +
                      _vm.periods.period_times +
                      '/' +
                      _vm.periods.period_year +
                      '/' +
                      'teach',"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" พิมพ์รายงาน แบบที่ 1 ข้อมูลดิบใช้พิจารณา")],1)],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'#/admin/print_report_condition_all/' +
                      _vm.periods.period_times +
                      '/' +
                      _vm.periods.period_year,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" พิมพ์รายงาน แบบที่ 2 แบบอัตราว่าง")],1)],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"red"},on:{"click":function($event){return _vm.search_not_confirm_submit()}}},[_c('v-icon',[_vm._v("mdi-account-search")]),_vm._v(" ไม่ได้บันทึกรายการ")],1)],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"warning"},on:{"click":function($event){return _vm.transference_locationQueryCC()}}},[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" แจ้งยกเลิกไฟล์แนบปลายทางรับฟังความคิดเห็น")],1)],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'#/admin/print_report_movement_formscore7/?period_times=' +
                      _vm.periods.period_times +
                      '&period_year=' +
                      _vm.periods.period_year,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" แบบบันทึกผลคะแนน กลั่นกรอง (7)")],1)],1)])])])],1),_c('v-select',{attrs:{"items":_vm.headers,"label":"เลือกคอลัมน์ที่ต้องการแสดง","multiple":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [(index < 6)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 6)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 6)+" คอลัมน์)")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.showHeaders,"items":_vm.transference_locations,"search":_vm.search,"loading":_vm.loading,"item-class":_vm.row_classes},scopedSlots:_vm._u([{key:"item.tpvecprovince_scoreFile",fn:function(ref){
                      var item = ref.item;
return [(item.tpvecprovince_scoreFile)?_c('v-btn',{attrs:{"text":"","rounded":"","href":'/HRvecfiles/' + item.tpvecprovince_scoreFile,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-printer")])],1):_vm._e()]}},{key:"item.select_item",fn:function(ref){
                      var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.new_college_code},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}},{key:"item.status_position",fn:function(ref){
                      var item = ref.item;
return [(item.status_position == null)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_c('v-icon',[_vm._v("mdi-close")])],1):_c('v-chip',{attrs:{"color":"green","dark":""}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.status_position))])])]}},{key:"item.comment_dr",fn:function(ref){
                      var item = ref.item;
return [(item.comment_dr === 'approp')?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"green","dark":""}},[_c('h2',[_vm._v("เห็นควร")])]):(item.comment_dr === 'inapprop')?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"warning","dark":""}},[_c('h2',[_vm._v("ไม่เห็นควร")])]):(item.comment_dr === 'suspend')?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("ระงับย้าย")]):_c('v-chip',{staticClass:"ma-1",attrs:{"color":"info","dark":""}},[_c('h2',[_vm._v("ยังไม่ได้บันทึกข้อมูล")])]),(item.status_document === 'complete')?_c('v-chip',{attrs:{"color":"green","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.comment_idPosition(item.tid_ref)}}},[_vm._v("ถูกต้องสมบูรณ์")]):(item.status_document === 'incomplete')?_c('v-chip',{attrs:{"color":"warning","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.comment_idPosition(item.tid_ref)}}},[_vm._v("ไม่สมบูรณ์")]):(item.status_document === 'do_not')?_c('v-chip',{attrs:{"color":"red","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.comment_idPosition(item.tid_ref)}}},[_vm._v("ไม่จัดส่ง")]):_vm._e()]}},{key:"item.Mcommittee",fn:function(ref){
                      var item = ref.item;
return [(item.tlstatus_transfer === 'cc')?_c('v-icon',{attrs:{"color":"red","large":""}},[_vm._v("mdi-information")]):_vm._e(),(item.Mcommittee === 'approp')?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" เห็นควร ")]):(item.Mcommittee === 'inapprop')?_c('v-chip',{attrs:{"color":"warning","dark":""},on:{"click":function($event){return _vm.cancelFile(item.id_tfl)}}},[_vm._v(" ไม่เห็นควร ")]):_vm._e(),(item.tlstatus_transfer === 'cc')?_c('v-icon',{attrs:{"large":"","color":"red"},on:{"click":function($event){return _vm.cancelFile(item.id_tfl)}}},[_vm._v("mdi-delete-circle")]):_vm._e()]}},{key:"item.MdocumentsLinkfile",fn:function(ref){
                      var item = ref.item;
return [(item.MdocumentsLinkfile)?_c('div',[_c('v-btn',{attrs:{"text":"","rounded":"","color":"green","large":"","href":'/HRvecfiles/' + item.MdocumentsLinkfile,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1):_c('div',[_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_vm._v(" ไม่ได้แนบไฟล์ ผลการพิจารณา/วาระประชุม ")])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-card-text',[_vm._v(_vm._s(_vm.snackbar.text))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }